import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostPreview from "../components/postPreview"

class CategoryTemplate extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.contentfulCategory.post;
    const category = data.contentfulCategory;

    return (
        <Layout location={this.props.location} title={siteTitle}>
            <SEO title={`Aktualności ${category.title}`} />
            <div className="container mx-auto">
                <div className="py-10">
                    <div className="sm:flex sm:items-center">
                        <div className="inline-flex w-12 h-12 items-center justify-around bg-main rounded-lg">
                            <svg className="fill-current text-white w-6 h-6" viewBox="-21 -47 682.66669 682" xmlns="http://www.w3.org/2000/svg"><path d="m552.011719-1.332031h-464.023438c-48.515625 0-87.988281 39.472656-87.988281 87.988281v283.972656c0 48.421875 39.300781 87.824219 87.675781 87.988282v128.871093l185.183594-128.859375h279.152344c48.515625 0 87.988281-39.472656 87.988281-88v-283.972656c0-48.515625-39.472656-87.988281-87.988281-87.988281zm-83.308594 330.011719h-297.40625v-37.5h297.40625zm0-80h-297.40625v-37.5h297.40625zm0-80h-297.40625v-37.5h297.40625zm0 0"/></svg>
                        </div>
                        <h1 className="text-4xl leading-none font-bold text-gray-800 ml-4">Najnowsze aktualności</h1>
                    </div>
                    <p className="text-gray-700 mt-3 lg:w-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam autem consequuntur dolorem hic itaque magnam nulla quisquam sunt ullam! Ex facere impedit ipsa obcaecati placeat quia, rem sed similique veniam!</p>
                </div>
                <div className="flex flex-wrap mb-8">
                    {posts.map((post , index) => {
                        return (
                            <PostPreview post={post} key={index} />
                        )
                    })}
                </div>
            </div>
        </Layout>
    )
  }
}

export default CategoryTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulCategory( slug: { eq: $slug }) {
      title
      slug
      createdAt(formatString: "DD.MM.Y HH:mm")
      post {
          createdAt(formatString: "DD.MM.Y HH:mm")
          title
          slug
          heroImage {
            fluid {
              srcSet
            }
            title
          }
          category {
            slug
            title
          }
          content {
            childContentfulRichText {
              html
            }
          }
          author {
            slug
            name
            avatar {
              title
              fluid {
                srcSet
              }
            }
      }
      }
    }
  }
`
